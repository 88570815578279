import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LandingPage from "./views/LandingPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Terms from "./views/Terms";
import DataSafety from "./views/DataSafety";
import DataSafetyConfirmation from "./views/DataSafetyConfirmation";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                path: "",
                element: <LandingPage />,
            },
            {
                path: "privacy_policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "terms",
                element: <Terms />,
            },
            {
                path: "download",
                element: <LandingPage />,
            },
            {
                path: "data-safety",
                element: <DataSafety />,
            },
            {
                path: "data-safety-confirmation",
                element: <DataSafetyConfirmation />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
