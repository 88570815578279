import "./App.css";
import Layout from "./views/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { checkLanguage } from "./utils/checkLanguage";

const App = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Cambio de idioma basado en la preferencia del navegador del usuario
    i18n.changeLanguage(checkLanguage());
  }, []);

  useEffect(() => {
    const appValue = searchParams.get("share");
    if (appValue) {
      window.location.replace(`ppa://share.app/${appValue}`);
      setTimeout(() => {
        // Utilizando tu lógica de detección de dispositivos simplificada
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
          window.location.href = "https://play.google.com/store/apps/details?id=com.prodigypadelacademy.app";
        } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
          window.location.href = "https://apps.apple.com/app/prodigy-padel-academy/id6463727496";
        }
        // No rediriges en otros casos, puedes ajustar esto según necesites
      }, 3000);
    }
  }, [pathname, searchParams]);

  return (
    <I18nextProvider i18n={i18n}>
      <Layout />
    </I18nextProvider>
  );
};

export default App;

